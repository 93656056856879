.button--primary {
  color: white;
  background-color: #f94470;
}
.button--secondary {
  color: #f94470;
  background-color: transparent;
  box-shadow: #f94470 0px 0px 0px 1px inset;
}
.button--small {
  font-size: 12px;
  padding: 6px 16px;
}
.button--medium {
  font-size: 14px;
  padding: 8px 21px;
}
.button--fullwidth {
  font-size: 16px;
  padding: 9.5px 24px;
  width: 100%;
}

.button-disable {
  background-color: #eee !important;
  color: #999 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
}
