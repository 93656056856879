@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .one-line-dot {
    @apply overflow-hidden whitespace-nowrap text-ellipsis;
  }
  .page-content-wrap {
    @apply pt-[50px] border-0;
  }
  .window-max-confg {
    @apply max-w-[500px] w-full;
  }
}

@layer utilities {
  .text-break {
    white-space: pre;
  }
}

#root,
html,
body,
.App {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
