@keyframes scrollUp {
  to {
    transform: translateY(-100%);
  }
}

@keyframes scrollDown {
  to {
    transform: translateY(100%);
  }
}
