.scroll {
  width: 200px;
  height: 400px;
  background: red;
  overflow: scroll;
}
.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0,.3); */
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}
